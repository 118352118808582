import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import BaseLayout from '../components/layouts/BaseLayout';
import Stage from '../components/layouts/Stage';
import StageIntro from '../components/elements/StageIntro';
import { Section, FlexContainer } from '../components/layouts/Containers';
import { Headline, P } from '../components/elements/Typography';
import { Img } from '../components/elements/Images';
import { List, ListItem } from '../components/elements/Lists';
import { Link } from '../components/elements/ButtonsAndLinks';
import { shapeImage } from '../helpers/shapes';
import {
    downloadSjfpProjektbeschreibungen2018,
    downloadSjfpAnalogesFormular2020,
} from '../helpers/downloads';

import logoLSVbwsj from '../images/logos/lsv-bwsj.png';
import logoKMBW from '../images/logos/km-bw.png';
import logoEuropapark from '../images/logos/europapark.png';
import { phased } from '../helpers/phases';

const AboutPage = ({ data }) => (
    <BaseLayout
        title="Über den Sportjugend Förderpreis"
        description="Informationen über den LOTTO Sportjugend-Förderpreis"
    >
        <Stage>
            <StageIntro
                headline={{ text: 'Über den LOTTO Sportjugend-Förderpreis', smaller: true }}
                copy="Der Wettbewerb für baden-württembergische Sportvereine."
            />
        </Stage>

        <Section container="m">
            <Headline level="h2">So funktioniert der Sportjugend-Förderpreis</Headline>
            <Headline level="h3">Was wird prämiert?</Headline>
            <P gap="l">
                Prämiert werden facettenreiche Projekte aus der Jugendarbeit. Sie reichen von
                Engagement für die Gesellschaft und das Gemeinwohl über Partizipation von Kindern
                und Jugendlichen bis hin zu Veranstaltungen und Freizeitaktivitäten. Auch auf
                Angebote der digitalen Jugendarbeit, auf Projekte zu Inklusion, Integration und
                Nachhaltigkeit sind wir gespannt.
            </P>
            <P>
                Ebenfalls prämiert werden Projekte und Aktionen, die geholfen haben, die Zeit der
                Corona-Pandemie zu überbrücken. Hier zeichnen wir auch innovative, alternative
                Trainingsideen aus sowie soziales Engagement außerhalb von Trainingsplatz oder
                –halle.
            </P>
            <P>
                Entscheidend ist nicht die Größe eures Vereins, sondern die Qualität eures
                Engagements! Es kommt darauf an, dass ihr mit euren Aktionen die Jugendarbeit im
                Verein fördert, die klassischen Werte des Sports vermittelt sowie Kreativität und
                Leistungsbereitschaft von Kindern stärkt.
            </P>
            <P bold>
                Dem Einfallsreichtum sind keine Grenzen gesetzt. Einige Anregungen findet ihr sicher
                in der Beschreibung der Siegerprojekte von der letzten Ausschreibung.
            </P>
            <List gap="xl" flex>
                <ListItem type="plain">
                    <Link type="download" to={downloadSjfpProjektbeschreibungen2018}>
                        Download Projektbeschreibungen 2018
                    </Link>
                </ListItem>
            </List>
            <P>Wir freuen uns auf Eure Bewerbung und wünschen Euch viel Erfolg!</P>
            <Headline level="h3">Wer kann mitmachen?</Headline>
            <List
                gap="xl"
                items={[
                    'Alle Sportvereine, die in den Mitgliedssportbünden bzw. Sportfachverbänden des Landessportverbandes Baden-Württemberg organisiert sind.',
                    'Pro Verein ist eine Bewerbung zugelassen, bei Mehrspartenvereinen eine Bewerbung pro Abteilung.',
                    'Sportfachverbände und Sportkreise sind von der Teilnahme ausgeschlossen. Kooperationsprojekte zwischen Sportvereinen und Fachverbänden/ Sportkreisen sind jedoch zulässig',
                ]}
            />
            <Headline level="h3">Wie bewerbe ich mich?</Headline>
            <P gap="l">
                Einfach das{' '}
                {phased([
                    <Link to="/online-bewerbung">Online-Bewerbungsformular</Link>,
                    <>Online-Bewerbungsformular</>,
                ])}{' '}
                ausfüllen oder das{' '}
                <Link to={downloadSjfpAnalogesFormular2020} target="_blank">
                    analoge Formular
                </Link>, gerne mit Anlagen, postalisch einreichen. Bewerbungsschluss ist der
                11.01.2021. Bei postalischen Einsendungen gilt das Datum des Poststempels.
            </P>
            <Headline level="h3">Jury</Headline>
            <P gap="m">
                Acht Jurorinnen und Juroren übernehmen die Aufgabe, die Preisträger zu ermitteln:
            </P>
            <Headline level="h3">Haben Sie noch Fragen?</Headline>
            <P>
                Bei Fragen wenden Sie sich bitte an die Geschäftsstelle der Baden-Württembergischen
                Sportjugend (0711/207049-863 / <Link to="mailto:bC5wb3JhZGFAbHN2YncuZGU=" /> oder an
                Lotto Baden-Württemberg 0711/81000-112 /{' '}
                <Link to="mailto:ZnJpZWRlbWFubi5oYWViZXJsZW5AbG90dG8tYncuZGU=" />).
            </P>
        </Section>

        <Section container="m">
            <Headline level="h2">Philosophie des LOTTO Sportjugend-Förderpreises</Headline>

            <Img
                title="Philosophie des Sportjugend-Förderpreises"
                alt="Hände übereinander"
                gap="l"
                src={data.contentAbout.childImageSharp.fluid}
            />

            <P>
                Kinder und Jugendliche auf ihre Zukunft vorzubereiten, ist eine der wichtigsten
                gesellschaftspolitischen Aufgaben. Solidarität, Teamgeist und Fairplay können sie
                insbesondere durch praktische Erfahrungen lernen. Auch die 11.300 Sportvereine in
                Baden-Württemberg beteiligen sich dabei in hohem Maß.
            </P>
            <P>
                Sie fördern die Kreativität. Sie stärken den Leistungswillen. Sie vermitteln Kindern
                und Jugendlichen das Selbstbewusstsein, das sie für einen verantwortungsvollen
                Umgang mit sich und ihrer Umwelt brauchen. Die Staatliche Toto-Lotto GmbH
                Baden-Württemberg hat es sich zum Ziel gesetzt, die Sportvereine bei dieser
                wichtigen Aufgabe zu unterstützen und die Bedeutung der Vereinsjugendarbeit im
                öffentlichen Bewusstsein stärker hervorzuheben.
            </P>
            <P>
                Selten war gesellschaftlicher Zusammenhalt stärker gefragt als in der Corona-Krise.
                Auch hier war auf die Sportvereine Verlass. Mit großer Flexibilität und Kreativität
                haben sie im Trainingsalltag improvisiert oder durch Nachbarschaftshilfe
                unterstützt.
            </P>
            <P bold>
                Um baden-württembergische Vereine mit vorbildlicher Jugendarbeit auszuzeichnen,
                wurde der LOTTO Sportjugend- Förderpreis ins Leben gerufen. Seit 1998 schreibt das
                Unternehmen den Wettbewerb in Zusammenarbeit mit der Baden-Württembergischen
                Sportjugend und dem Kultusministerium aus. Über 2.300 Vereine nahmen bisher teil.
                Die Preisgelder lagen in Summe bei über einer Million Euro.
            </P>
        </Section>

        <Section
            container="m"
            gap={{
                small: 'xxl',
                medium: 'xxxl',
                large: 'xxxxl',
            }}
        >
            <Headline level="h2" gap="none">
                Dank an die Partner
            </Headline>

            <P size="s" gap="xl" textAlign="center">
                Die Staatliche Toto-Lotto GmbH Baden-Württemberg bedankt sich bei ihren Partnern,
                dem Ministerium für Kultus, Jugend und Sport und der Baden-Württembergischen
                Sportjugend, vor allem jedoch auch bei den Jurymitgliedern für die gute
                Zusammenarbeit sowie beim Europa-Park für die freundliche Unterstützung.
            </P>

            <FlexContainer grid size="m" textAlign="center" justifyContent="space-around">
                <Link
                    to="https://www.lsvbw.de/sportwelten/sportjugend/"
                    target="_blank"
                    rel="noopener noreferrer"
                    display="block"
                    layer={1}
                    gap="m"
                >
                    <Img
                        title="Baden-Württembergische Sportjugend (BWSJ) im Landessportverband Baden-Württemberg"
                        alt="Logo des LSV/bwsj"
                        src={logoLSVbwsj}
                    />
                </Link>
                <Link
                    to="https://www.km-bw.de/,Lde/Startseite"
                    target="_blank"
                    rel="noopener noreferrer"
                    display="block"
                    layer={1}
                    gap="m"
                >
                    <Img
                        title="Baden-Württembergische Sportjugend (BWSJ) im Landessportverband Baden-Württemberg"
                        alt="Logo des LSV/bwsj"
                        src={logoKMBW}
                    />
                </Link>
                <Link
                    to="https://www.europapark.de/de"
                    target="_blank"
                    rel="noopener noreferrer"
                    display="block"
                    layer={1}
                    gap="m"
                >
                    <Img title="Europapark" alt="Logo des Europaparks" src={logoEuropapark} />
                </Link>
            </FlexContainer>
        </Section>
    </BaseLayout>
);

AboutPage.propTypes = {
    data: PropTypes.shape({
        contentAbout: shapeImage,
    }).isRequired,
};

export default AboutPage;

export const query = graphql`
    query AboutQuery {
        contentAbout: file(relativePath: { eq: "content/about.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1000, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        }
    }
`;
